// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'first', {
    value() {
        return this.length ? this.find((x) => x) : null;
    },
});
const objectFlatten = (list) =>
    list.reduce((agg, { children, ...node }) => {
        agg.push(node);

        if (children) {
            agg.push(...objectFlatten(children));
        }

        return agg;
    }, []);

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'flatten', {
    value() {
        return objectFlatten(this);
    },
});

/* Shift elements to the right by positions */
/* Example: [1, 2, 3, 4, 5].shiftRight(2) => [4, 5, 1, 2, 3] */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'shiftRight', {
    value(positions = 1) {
        let position = positions;
        const len = this.length;
        if (len <= 1) return this;
        
        position %= len; // Handle cases where positions > array length
        return [...this.slice(-position), ...this.slice(0, -position)];
    },
});
